import React from "react"
import Box from "@mui/system/Box";
import useResizeObserver from "use-resize-observer";
import useWindowDimensions from "../../../utils/useWindowDimensions"
import AbsImagesGrouped2Lazy from "./AbsImagesGrouped2Lazy"


const styleSxWrap = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    mx: 1,
    my: 0,
    "& .carouselItem": {
        width: "100%",
        height: "100%",
        position: "relative",

        "& >img": {
            width: "inherit",
            display: "block",
        },
        "& .itemImgs": {
            position: "relative",
            height: "inherit",
            width: "inherit",
            "& .fig": {
                height: "inherit",
                width: "inherit",
                margin: 0,
                overflow: "hidden",
                transformStyle: "preserve-3d",
            },
        },

    },
}


const borderWidth = 21
let maxAspectRatio = 0
const borderImgWidth = 20

const AbsImagesAnimation = () => {
    const { ref, width = 1 } = useResizeObserver(); //, height = 1 
    const windowDim = useWindowDimensions()
    maxAspectRatio = 3.271604938271605

    const heightContainer = Math.round((windowDim.width / maxAspectRatio) + 3 * borderWidth)

    const currentWidth = Math.round(width) //+ 3 * borderWidth
    const heightItemWitoutBorders = heightContainer - 2 * borderImgWidth

    return (
        <Box component="div"
            id="headCarou"
            sx={styleSxWrap}
            ref={ref}
        >
            <div
                className="carouselItem"
                key="carouselItem"
                style={{
                    height: `${heightItemWitoutBorders}px`,
                    marginTop: `${borderImgWidth}`,
                    marginBottom: `${borderImgWidth}`,
                    overflow: "hidden",

                }}
            >
                <div
                    className="itemImgs"
                    key={`-itemImgs`}
                >
                    <div
                        className="fig"
                    >
                    </div>
                </div>

                {currentWidth > 1 &&
                    (
                        <AbsImagesGrouped2Lazy
                            grContainerHeight={heightItemWitoutBorders}
                            grContainerWidth={currentWidth}
                            topShift={borderImgWidth}
                        />
                    )
                }
            </div>
        </Box>
    )
}
export default AbsImagesAnimation
